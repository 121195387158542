/**
 * ParticipantsFilterModels type definition
 * @global
 * @typedef {Object}       ParticipantsFilterModels
 * @typedef {FilterModel}  [ModelOptions.participants]                - Participants filter model
 * @typedef {FilterModel}  [ModelOptions.participantsDistribution]    - Participants Distribution filter model
 * @typedef {FilterModel}  [ModelOptions.allocation]                  - Allocation filter model
 */

// Ensure other child component APIs are loaded on time
import registeredEvents from '../../../js/helpers/registered-events'
import { participantsFilterEvents } from '../../../js/document/event-types'

require('../../components/participants-selector/main')
const EventEmitter = require('eventemitter3')

const WIDGET_API = 'w-participants-filter'
const WIDGET_QUERIES = {
  participants: `[data-${WIDGET_API}__participants]`,
  track: 'data-track'
}

export default class ParticipantsFilter {
  /**
   * Creates a new ParticipantsFilter
   *
   * @constructor
   *
   * @param {HTMLElement} element - The element where to attach ParticipantsFilter
   * @param {Object} filterModels
   * @param {FilterModel} filterModels.participants
   * @param {FilterModel} [filterModels.allocation]
   */
  constructor (element, filterModels) {
    this.element = element
    this.events = new EventEmitter()

    registeredEvents.registerWidgetEvents(WIDGET_API, this.events, {
      ...this.element.hasAttribute(WIDGET_QUERIES.track) && { track: this.element.attributes[WIDGET_QUERIES.track].value }
    })
    this.element.setAttribute('data-js-component', WIDGET_API)
    this.participantsElement = this.element.querySelector(WIDGET_QUERIES.participants)
    this.participantsApi = this.participantsElement['c-participants-selector']

    this.participantsFilter = filterModels.participants
    this.allocationFilter = filterModels.allocation
    this.setParticipantsPropsFromFilters({ silent: false })

    // Bind participants selector submit event
    this.participantsApi.events.on('submit', (props) => {
      this.setFiltersFromParticipantsProps(props)
    })

    // Bind filterModels change event
    this.participantsFilter.events.on('change', () => {
      this.setParticipantsPropsFromFilters({ silent: true })
    })
    if (this.allocationFilter) {
      this.allocationFilter.events.on('change', () => {
        this.setParticipantsPropsFromFilters({ silent: true })
      })
    }
    this.element[WIDGET_API] = {
      open: this.participantsApi.open
    }
  }

  setFilterModel (filterModel) {
    this.participantsFilter = filterModel.participants
    this.allocationFilter = filterModel.allocation
    this.setParticipantsPropsFromFilters({ silent: true })

    this.participantsFilter.events.on('change', () => {
      this.setParticipantsPropsFromFilters({ silent: true })
    })
    if (this.allocationFilter) {
      this.allocationFilter.events.on('change', () => {
        this.setParticipantsPropsFromFilters({ silent: true })
      })
    }

    return this
  }

  /**
   * Sets participants selector properties from filterModels data
   *
   * @param {Object} options - setter options passed to participantsApi
   *
   * @returns {ParticipantsFilter} self instance
   */
  setParticipantsPropsFromFilters (options) {
    const participants = this.participantsFilter.values.getFlattenSelectedValues()
    const participantsDistribution = this.participantsFilter.values.getParticipantsDistribution()
    const allocation = this.allocationFilter
      ? this.allocationFilter.getSelectedValues()[0]
      : ''
    const allocationOptions = this.allocationFilter
      ? this.allocationFilter.values
        .pick('value', 'caption', 'isSelected')
        .map(({ value, caption, isSelected }) => ({
          value,
          id: `-${value}`,
          text: caption,
          checked: isSelected
        }))
      : undefined

    this.participantsApi.setProp('humanizedTextPattern', this.participantsFilter.attributes.humanizedTextPattern || '')

    // this.participantsFilter.attributes.ageProfiles will only be set on the QuickSearch
    if (this.participantsFilter.attributes.ageProfiles && this.participantsFilter.attributes.ageProfiles.length > 0) {
      this.participantsApi.setProp('ageProfiles', this.participantsFilter.attributes.ageProfiles)
    }

    const maxRooms = this.participantsFilter.attributes.maxRooms || 3

    if (allocationOptions) {
      this.participantsApi.updateAllocationOptions(allocationOptions)
      this.participantsApi.setProp('maxRooms', 1)
    } else {
      this.participantsApi.updateAllocationOptions([])
      if (this.participantsApi.getProp('maxRooms') === 1) {
        this.participantsApi.setProp('maxRooms', maxRooms)
      }
    }

    this.participantsApi.updateParticipantsData(participants, allocation, participantsDistribution)

    return this
  }

  /**
   * Sets filterModels values from participants selector props
   *
   * @param {Object} props - props passed by component
   * @param {Object} options - setter options
   * @param {Boolean} options.silent - changes will be made silently
   *
   * @returns {ParticipantsFilter} self instance
   */
  setFiltersFromParticipantsProps (props, options = {}) {
    const participantsBirthdates = props.participants || this.participantsApi.getProp('participants')
    const participantsDistribution = props.participantsDistribution || this.participantsApi.getProp('participantsDistribution')
    const allocation = props.allocation || this.participantsApi.getProp('allocation')
    const participantsValues = participantsBirthdates.map((birthdate, i) => {
      const roomIndex = participantsDistribution.findIndex(room => room.split('|').includes(`${i + 1}`))
      return {
        isSelected: true,
        isAvailable: true,
        caption: birthdate,
        value: birthdate,
        metadata: `Room:${roomIndex >= 0 ? roomIndex : 0}`,
        groupInfo: null
      }
    })
    this.participantsFilter.values.reset(participantsValues, { silent: true })

    if (this.allocationFilter) {
      this.allocationFilter.clearSelection({ silent: true })
      this.allocationFilter.setSelectedValues([allocation], true, { silent: true })
    }

    // Trigger the event on the main participants filter, if proceeds
    if (!options || !options.silent) {
      this.participantsFilter.events.emit('change')
    }

    const ageProfiles = this.participantsApi.getParticipantsByAgeProfiles()
    this.events.emit(participantsFilterEvents.PARTICIPANTS_FILTER__FILTER_APPLIED, ageProfiles)

    return this
  }
}
