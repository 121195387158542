
// Ensure other child component APIs are loaded on time
require('../../components/dropdown/dropdown-multiple/main')

const widgetApi = 'w-dropdown-multiple-filter'
const widgetQueries = {
  dropdown: `[data-${widgetApi}__dropdown]`
}

export default class DropdownMultipleFilter {
  /**
   * Creates a new DropdownMultipleFilter
   *
   * @constructor
   *
   * @param {HTMLElement} element - The element where to attach DropdownMultipleFilter
   * @param {FilterModel} filterModel - The associated FilterModel
   */
  constructor (element, filterModel) {
    this.element = element
    this.element.setAttribute('data-js-component', widgetApi)
    this.dropdownElement = this.element.querySelector(widgetQueries.dropdown)
    this.dropdownApi = this.dropdownElement['c-dropdown-multiple']

    this.setFilterModel(filterModel)

    // Bind dropdown events to filterModel
    this.dropdownApi.events.on('submit', () => {
      this.setDropdownOptionsToFilter()
    })
    this.dropdownApi.setProp('saveOnDomClick', true)
    this.element[widgetApi] = {
      open: this.dropdownApi.open
    }
  }

  /**
   * Set the current filterModel
   * - Set given filterModel to filterModel self property
   * - Set props on child views
   * - Bind filter model events
   *
   * @param {FilterModel} filterModel - The associated FilterModel
   *
   * @returns {DropdownMultipleFilter} self instance
   */
  setFilterModel (filterModel) {
    this.filterModel = filterModel

    this.dropdownApi.setProps({
      options: this.getDropdownOptionsFromFilter(),
      optionsData: this.getDropdownOptionsDataFromFilter()
    })

    this.filterModel.events.on('change', () => {
      this.dropdownApi.setProps({
        options: this.getDropdownOptionsFromFilter(),
        optionsData: this.getDropdownOptionsDataFromFilter()
      })
    })

    return this
  }

  /**
   * Set the current Dropdown selectedValues to filter
   *
   * @returns {DropdownMultipleFilter} self instance
   */
  setDropdownOptionsToFilter () {
    const newSelectedValues = this.dropdownApi.getSelectedValues()
    this.filterModel
      .clearSelection({ silent: true })
      .setSelectedValues(newSelectedValues)
    return this
  }

  /**
   * Get the current Dropdown options from filter
   *
   * @returns {RadioButtonData[]|CheckboxData[]} The parsed filter to Dropdown options
   */
  getDropdownOptionsFromFilter () {
    return this.filterModel.values.models.map(model => ({
      id: model.cid,
      name: model.getAttribute('value'),
      value: model.getAttribute('value'),
      text: model.getAttribute('caption'),
      checked: model.getAttribute('isSelected'),
      disabled: !model.getAttribute('isAvailable'),
      count: model.getAttribute('count')
    }))
  }

  /**
   * Get the current Dropdown optionsData from filter
   *
   * @returns {Object[]} The parsed optionsData
   */
  getDropdownOptionsDataFromFilter () {
    return this.filterModel.values.models.map(model => ({
      value: model.getAttribute('value'),
      text: model.getAttribute('caption')
    }))
  }
}
