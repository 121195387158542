import ValueModel from '../value-model'

/**
 * An airport filter model
 */
export default class AirportModel extends ValueModel {
  /**
   * Creates a new AirportModel
   *
   * @constructor
   * @param {ValueModelAttributes} [attributes] - The model attributes
   * @param {ModelOptions} [options] - Model options
   */
  constructor (attributes = {}, options = {}) {
    super(attributes, options)
    this.setAttributes({
      group: this.getValueFromMetaData('Group')
    }, { silent: true })
  }
}
