import ValueModel from '../value-model'

/**
 * A Participant filter model
 */
export default class ParticipantModel extends ValueModel {
  /**
   * Creates a new ParticipantModel
   *
   * @constructor
   * @param {ValueModelAttributes} [attributes] - The model attributes
   * @param {ModelOptions} [options] - Model options
   */
  constructor (attributes = {}, options = {}) {
    super(attributes, options)
    this.setAttributes({
      room: parseInt(this.getValueFromMetaData('Room'))
    }, { silent: true })
  }
}
