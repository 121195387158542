import DropdownMultipleFilter from '../dropdown-multiple-filter/main'
import { toCamelCase } from '../../../js/helpers/string'

export default class AirportFilter extends DropdownMultipleFilter {
  /**
   * Get the current Dropdown options from filter
   * - The count is filled with the AirportCode (value)
   *
   * @returns {RadioButtonData[]|CheckboxData[]} The parsed filter to Dropdown options
   */
  getDropdownOptionsFromFilter () {
    const airportGroups = this.filterModel.values.airportGroups
    const airports = this.filterModel.values

    return airportGroups.models.length
      ? airportGroups.models.map(group => ({
        id: toCamelCase(group.getAttribute('value')),
        name: group.getAttribute('value'),
        value: group.getAttribute('value'),
        text: group.getAttribute('caption'),
        checked: group.getAttribute('isSelected'),
        disabled: !group.getAttribute('isAvailable'),
        items: airports.getAirportsByGroup(group.getAttribute('value'))
          .map(airport => ({
            id: airport.cid,
            name: airport.getAttribute('value'),
            value: airport.getAttribute('value'),
            text: airport.getAttribute('caption'),
            count: airport.getAttribute('value'),
            checked: airport.getAttribute('isSelected'),
            disabled: !airport.getAttribute('isAvailable')
          })
          )
      })
      )
      : airports.models
        .map(airport => ({
          id: airport.cid,
          name: airport.getAttribute('value'),
          value: airport.getAttribute('value'),
          text: airport.getAttribute('caption'),
          count: airport.getAttribute('value'),
          checked: airport.getAttribute('isSelected'),
          disabled: !airport.getAttribute('isAvailable')
        })
        )
  }

  /**
   * Set the current Dropdown selectedValues to filter
   * - The airportGroups should also reflect the changes
   *
   * @returns {DropdownMultipleFilter} self instance
   */
  setDropdownOptionsToFilter () {
    const newSelectedValues = this.dropdownApi.getSelectedValues()
    const airportGroups = this.filterModel.values.airportGroups
    airportGroups
      .clearSelection({ silent: true })
      .setSelectedValues(newSelectedValues, true, { silent: true })
    this.filterModel
      .clearSelection({ silent: true })
      .setSelectedValues(newSelectedValues)
    return this
  }

  /**
   * Get the current Dropdown chipsData from filter
   * - The text should be the AirportCode (value) instead of caption
   *
   * @returns {Object[]} The parsed chipsData
   */
  getDropdownOptionsDataFromFilter () {
    const airportGroups = this.filterModel.values.airportGroups
    const airports = this.filterModel.values
    return [
      ...airportGroups.models.map(model => ({
        value: model.getAttribute('value'),
        text: model.getAttribute('caption')
      })),
      ...airports.models.map(model => ({
        value: model.getAttribute('value'),
        text: model.getAttribute('caption')
      }))
    ]
  }
}
