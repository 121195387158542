import ValueModel from '../value-model'

/**
 * A destination filter model
 */
export default class DestinationModel extends ValueModel {
  /**
   * Creates a new DestinationModel
   *
   * @constructor
   * @param {ValueModelAttributes} [attributes] - The model attributes
   * @param {ModelOptions} [options] - Model options
   */
  constructor (attributes = {}, options = {}) {
    super(attributes, options)
    this.setAttributes({
      type: this.getValueFromMetaData('Type'),
      parentId: this.getValueFromMetaData('ParentId'),
      canonicalName: this.getValueFromMetaData('CanonicalName'),
      siteType: this.getValueFromMetaData('SiteType')
    }, { silent: true })
  }

  /**
   * Returns the parent models
   *
   * @returns {DestinationModel[]}
   */
  getParentModels () {
    if (!this.getAttribute('parentId') || !this.collection) return []
    const parentModel = this.collection.findWhere('value', this.getAttribute('parentId'))
    return parentModel ? [parentModel].concat(parentModel.getParentModels()) : []
  }
}
