import Model from '../../core/model'
import ValuesCollection from './values-collection'
import AirportsCollection from './airports/airports-collection'
import DestinationsCollection from './destinations/destinations-collection'
import DatesCollection from './dates/dates-collection'
import ParticipantsCollection from './participants/participants-collection'

const knownFilterSpecialTypes = {
  Participants: ParticipantsCollection,
  DepartureAirport: AirportsCollection,
  ArrivalAirport: AirportsCollection,
  DepartureDate: DatesCollection,
  Destination: DestinationsCollection
}

/**
 * The FilterModelAttributes contains all data for a new filter model
 *
 * @typedef {Object}          FilterModelAttributes
 *
 * @property {String}         type                - The filter type defines what data it contains
 * @property {Boolean}        isMultiselectable   - The filter can contain more than one value
 * @property {(String|null)}  [parentFilterType]  - The parent filter type or ID
 * @property {Model[]}        values              - The collection o values for a filter
 */

/**
 * A filter model
 */
export default class FilterModel extends Model {
  /**
   * Creates a new FilterModel
   *
   * @constructor
   *
   * @param {FilterModelAttributes} attributes - The filter model attributes
   */
  constructor (attributes = {}) {
    super(attributes, {
      defaults: {
        type: null,
        isMultiselectable: false,
        parentFilterType: null,
        isMandatory: false,
        values: []
      }
    })

    const TypeValuesCollection = knownFilterSpecialTypes[this.getAttribute('type')] || ValuesCollection
    this.values = new TypeValuesCollection(this.attributes.values)

    this.values.events.on('updated', () => this.events.emit('change'))
  }

  /**
   * Returns the selected models
   *
   * @returns {Model[]}
   *
   */
  getSelectedModels () {
    return this.values.getSelectedModels()
  }

  /**
   * Returns the selected values
   *
   * @returns {Object[]} Plain selected values
   * {value, caption}
   *
   */
  getSelectedValues () {
    return this.values.getSelectedValues()
  }

  /**
  * Returns true if the model has selected values
  *
  * @returns {Boolean}
  *
  */
  hasSelectedValues () {
    return this.getSelectedValues().length > 0
  }

  /**
   * Returns the selected values for filters, manipulated if necessary
   *
   * @returns {Object[]} Plain selected values
   * {value, caption}
   *
   */
  getFilterSelectedValues () {
    return this.values.getFilterSelectedValues
      ? this.values.getFilterSelectedValues()
      : this.values.getSelectedValues()
  }

  /**
   * @returns {Boolean}
   */
  isMultiselectable () {
    return this.getAttribute('isMultiselectable')
  }

  /**
   * @returns {Boolean}
   */
  isMandatory () {
    return this.getAttribute('isMandatory')
  }

  /**
   * Set selected values
   * Just a port to same values function
   *
   * @returns {FilterModel} - Self instance
   */
  setSelectedValues (...args) {
    this.values.setSelectedValues(...args)
    return this
  }

  /**
   * Clears all selected values
   *
   * @param {ModelActionOptions} [options]
   *
   * @returns {FilterModel} - The self instance
   */
  clearSelection (options) {
    this.values.clearSelection(options)
    return this
  }

  /**
   * Checks if offsetting values will be permitted by given offsetAmount
   * Will not happen when filter is multiSelectable
   *
   * @param {Number} offsetAmount - The offsetAmount to check
   *
   * @returns {Boolean} - True if possible
   */
  checkOffsetAvailable (offsetAmount) {
    if (this.isMultiselectable()) return false
    return this.values.checkOffsetAvailable(offsetAmount)
  }

  /**
   * Offsets selectedValue by given offsetAmount, if possible
   * Will not happen when filter is multiSelectable
   *
   * @param {Number} offsetAmount - The offsetAmount to check
   * @param {ModelActionOptions} [options]
   *
   * @returns {Boolean} - True if done
   */
  offsetSelected (offsetAmount, options) {
    if (this.isMultiselectable()) return false
    return this.values.offsetSelected(offsetAmount, options)
  }

  /**
   * Get the value of the item in the offset position relatively to the selected item, if possible
   *
   * @param {Number} offsetAmount - The offsetAmount to get
   *
   * @returns {(Model|undefined)} - The value of the filter if the offset is valid, otherwise undefined
   */
  getValueByOffset (offsetAmount) {
    if (this.isMultiselectable()) return undefined
    return this.values.getValueByOffset(offsetAmount)
  }
}
