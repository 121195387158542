import ParticipantModel from './participant-model'
import ValuesCollection from '../values-collection'

/**
 * A collection of values
 */
export default class ParticipantsCollection extends ValuesCollection {
  /**
   * Creates a new ParticipantsCollection
   *
   * @constructor
   * @param {(Object[]|Model[])} [items] - The items to populate collection
   * @param {CollectionOptions} [options] - The collection options
   */
  constructor (items = [], options = {}) {
    super(items, { ...options, model: ParticipantModel })
    this._hasRoomInfo = (this.models.length && !isNaN(this.models[0].getAttribute('room')))
  }

  /**
   * ⚠️ Override getSelectedValues method to return a multidimensional array
   */
  getSelectedValues () {
    return this.getParticipantsByRoom()
  }

  /**
   * Backwards compatible getSelectedValues from inherited class
   */
  getFlattenSelectedValues () {
    return super.getSelectedValues()
  }

  /**
   * Returns a multidimensional array with the participants (BirthDates) organized into rooms allocation,
   * eg. [ ['1979-01-01','1985-03-03'], ['2013-01-01','2015-03-03'] ]
   *
   * @returns {[DateString[]]}
   */
  getParticipantsByRoom () {
    // Handle no models case
    if (!this.models.length) return []
    // Handle no metadata case
    if (!this._hasRoomInfo) return [super.getSelectedValues()]

    let roomNumber = 0
    let moreResults = true
    const result = []

    do {
      const currentRoomParticipants = this.where('room', roomNumber)
        .map(participant => participant.getAttribute('value'))
      if (currentRoomParticipants.length) {
        result.push(currentRoomParticipants)
        roomNumber++
      } else {
        moreResults = false
      }
    } while (moreResults)

    return result
  }

  /**
   * Returns an array with the participants pipe separated indexes organized into rooms allocation (one based)
   * eg. ['1|2','3|4']
   *
   * @returns {String[]}
   */
  getParticipantsDistribution () {
    // Handle no models case
    if (!this.models.length) return []
    // Handle no metadata case
    if (!this._hasRoomInfo) return [[...Array(this.models.length).keys()].map(k => k + 1).join('|')]

    let roomNumber = 0
    let moreResults = true
    const result = []

    do {
      const currentRoomParticipants = this.where('room', roomNumber)
        .map(participant => this.models.indexOf(participant) + 1)
        .join('|')
      if (currentRoomParticipants.length) {
        result.push(currentRoomParticipants)
        roomNumber++
      } else {
        moreResults = false
      }
    } while (moreResults)

    return result
  }
}
