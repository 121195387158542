
/**
 * removeUndefinedKeys
 * Removes undefined keys from object.
 * By default returns a copy of the original object, but can be the same with second argument
 *
 * @param {Object} obj
 * @param {Boolean} copy - If true, object copy, otherwise same object
 *
 * @returns {Object} transformed result
 */
export const removeUndefinedKeys = (obj, copy = true) => {
  const returnObj = copy ? { ...obj } : obj
  Object.keys(returnObj)
    .forEach(key => returnObj[key] === undefined ? delete returnObj[key] : '')
  return returnObj
}

export function isEmptyObject (obj) {
  if (!obj) return true
  return (Object.keys(obj).length === 0 && obj.constructor === Object)
}
