import ValueModel from '../value-model'

/**
 * A date filter model
 */
export default class DateModel extends ValueModel {
  /**
   * Creates a new DateModel
   *
   * @constructor
   * @param {ValueModelAttributes} [attributes] - The model attributes
   * @param {ModelOptions} [options] - Model options
   */
  constructor (attributes = {}, options = {}) {
    super(attributes, options)
  }
}
