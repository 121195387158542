
// Ensure other child component APIs are loaded on time
require('../../components/dropdown/main')

const widgetApi = 'w-dropdown-filter'
const widgetQueries = {
  dropdown: `[data-${widgetApi}__dropdown]`
}

export default class DropdownFilter {
  /**
   * Creates a new DropdownFilter
   *
   * @constructor
   *
   * @param {HTMLElement} element - The element where to attach DropdownFilter
   * @param {FilterModel} filterModel - The associated FilterModel
   */
  constructor (element, filterModel) {
    this.element = element

    this.dropdownElement = this.element.querySelector(widgetQueries.dropdown)
    this.dropdownApi = this.dropdownElement['c-dropdown']
    this.element.setAttribute('data-js-component', widgetApi)
    this.setFilterModel(filterModel)

    // Bind dropdown events to filterModel
    this.dropdownApi.events.on('change', (value) => {
      this.setDropdownOptionsToFilter(value)
    })
    this.element[widgetApi] = {
      open: this.dropdownApi.open
    }
  }

  /**
   * Set the current filterModel
   * - Set given filterModel to filterModel self property
   * - Set options on dropdown
   * - Bind filter model events
   *
   * @param {FilterModel} filterModel - The associated FilterModel
   *
   * @returns {DropdownFilter} self instance
   */
  setFilterModel (filterModel) {
    this.filterModel = filterModel
    // Setting options silently so that value prop changed is not triggered in case it changes when changing options
    const setDropdownOptions = () => this.dropdownApi.setProp('options', this.getDropdownOptionsFromFilter(), { silent: true })
    setDropdownOptions()

    this.filterModel.events.on('change', () => {
      setDropdownOptions()
    })

    return this
  }

  /**
   * Set the current Dropdown selectedValues to filter
   *
   * @returns {DropdownFilter} self instance
   */
  setDropdownOptionsToFilter (value) {
    this.filterModel
      .clearSelection({ silent: true })
      .setSelectedValues([value])
    return this
  }

  /**
   * Get the current Dropdown options from filter
   *
   * @returns {DropdownSingleOptionData[]} The parsed filter to Dropdown options
   */
  getDropdownOptionsFromFilter () {
    return this.filterModel.values.models.map(model => ({
      value: model.getAttribute('value'),
      text: model.getAttribute('caption'),
      count: model.getAttribute('count'),
      selected: model.getAttribute('isSelected'),
      disabled: !model.getAttribute('isAvailable')
    }))
  }
}
