import Collection from '../../../core/collection'
import DestinationModel from './destination-model'
import ValuesCollection from '../values-collection'

/**
 * A collection of values
 */
export default class DestinationsCollection extends ValuesCollection {
  /**
   * Creates a new DestinationsCollection
   *
   * @constructor
   * @param {(Object[]|Model[])} [items] - The items to populate collection
   * @param {CollectionOptions} [options] - The collection options
   */
  constructor (items = [], options = {}) {
    super(items, { ...options, model: DestinationModel })
  }

  /**
   * Returns all models with type defined as 'country'
   *
   * @returns {DestinationModel[]}
   */
  getCountries () {
    return this.where('type', 'country')
  }

  /**
   * Returns all models matching given parent
   * and type defined as 'region'
   *
   * @param {(String|DestinationModel)} country - Current country to search for. If a string is given as country, will be considered as value.
   *
   * @returns {DestinationModel[]}
   */
  getRegionsByCountry (country) {
    const countryId = country instanceof this.Model ? country.getAttribute('value') : country
    return this.where('parentId', countryId).filter(region => region.getAttribute('type') === 'region')
  }

  /**
   * Returns all models matching given parent
   * and type defined as 'city'
   *
   * @param {(String|DestinationModel)} region - Current region to search for. If a string is given as region, will be considered as value.
   *
   * @returns {DestinationModel[]}
   */
  getCitiesByRegion (region) {
    const regionId = region instanceof this.Model ? region.getAttribute('value') : region
    return this.where('parentId', regionId).filter(city => city.getAttribute('type') === 'city')
  }

  /**
   * Returns all country & region models sorted
   *
   * @returns {DestinationModel[]}
   */
  getSortedCountriesAndRegions () {
    const result = []
    Collection.sortModelsBy('caption', this.getCountries())
      .forEach(country => {
        result.push(country, ...Collection.sortModelsBy('caption', this.getRegionsByCountry(country)))
      })
    return result
  }
}
