import ValueModel from './value-model'
import Collection from '../../core/collection'

/**
 * ValueModelAttributes type definition
 * @global
 * @typedef {Object}   ValueModelAttributes
 * @typedef {Boolean}  ValueModelAttributes.isSelected      - Selected state
 * @typedef {Boolean}  ValueModelAttributes.isAvailable     - Selectable state
 * @typedef {*}        ValueModelAttributes.value           - RAW value
 * @typedef {String}   ValueModelAttributes.caption         - Text to display
 * @typedef {Number}  [ValueModelAttributes.count]          - Number of expected results once applied
 * @typedef {String}  [ValueModelAttributes.metadata]       - Additional information
 * @typedef {String}  [ValueModelAttributes.groupInfo]      - Unknown property
 */

/**
 * A collection of values
 */
export default class ValuesCollection extends Collection {
  /**
   * Creates a new ValuesCollection
   *
   * @constructor
   * @param {Object[]|Model[]} [items] - The items to populate collection
   * @param {CollectionOptions} [options] - The collection options
   */
  constructor (items = [], options = {}) {
    options.model = options.model || ValueModel
    options.defaults = options.defaults || {
      isSelected: false,
      isAvailable: true,
      value: null,
      caption: null
    }
    super(items, options)
  }

  /**
   * Returns all selected models
   *
   * @returns {Model[]} - The selected models
   */
  getSelectedModels () {
    return this.where('isSelected', true)
  }

  /**
   * Returns all selected values
   *
   * @returns {*[]} - The selected values
   */
  getSelectedValues () {
    return this
      .getSelectedModels()
      .map(model => model.getAttribute('value'))
  }

  /**
   * Set selected values
   *
   * @param {Model[]|String[]} values - The values to set for
   * @param {Boolean} [selectedState=true] - The selectedState to set on every value
   * @param {ModelActionOptions} [options]
   *
   * @returns {ValuesCollection} - The self instance
   */
  setSelectedValues (values, selectedState = true, options = {}) {
    values.forEach((value) => {
      const valueModel = value instanceof ValueModel
        ? value
        : this.getModelByValue(value)
      if (valueModel) valueModel.setSelection(selectedState, { silent: true })
    })
    if (!options.silent) this.events.emit('updated')
    return this
  }

  /**
   * Clears all selected values
   *
   * @param {ModelActionOptions} [options]
   *
   * @returns {ValuesCollection} - The self instance
   */
  clearSelection (options = {}) {
    this.getSelectedModels().forEach(model => model.setSelection(false, { silent: true }))
    if (!options.silent) { this.events.emit('updated') }
    return this
  }

  /**
   * Returns all available models
   *
   * @returns {*[]} - The available values
   */
  getAvailableModels () {
    return this.where('isAvailable', true)
  }

  /**
   * Returns all available values
   *
   * @returns {*[]} - The available values
   */
  getAvailableValues () {
    return this
      .getAvailableModels()
      .map(model => model.getAttribute('value'))
  }

  /**
   * Gets a model by given value
   *
   * @param {*} value - The value to search for
   *
   * @returns {(Model|undefined)} - The match model, if there's any
   */
  getModelByValue (value) {
    return this.findWhere('value', value)
  }

  /**
   * Checks if offsetting values will be permitted by given offsetAmount
   *
   * @param {Number} offsetAmount - The offsetAmount to check
   *
   * @returns {Boolean} - True if possible
   */
  checkOffsetAvailable (offsetAmount) {
    const maxIndex = this.models.length - 1
    const currentIndex = this.models.indexOf(this.getSelectedModels()[0])
    const newIndex = currentIndex + offsetAmount
    return (newIndex >= 0 && newIndex <= maxIndex)
  }

  /**
   * Offsets selectedValue by given offsetAmount, if possible
   *
   * @param {Number} offsetAmount - The offsetAmount to check
   * @param {ModelActionOptions} [options]
   *
   * @returns {Boolean} - True if done
   */
  offsetSelected (offsetAmount, options) {
    if (!this.checkOffsetAvailable(offsetAmount)) return false
    const currentIndex = this.models.indexOf(this.getSelectedModels()[0])
    const newIndex = currentIndex + offsetAmount
    this.clearSelection({ silent: true }).setSelectedValues([this.models[newIndex]], true, options)
    return true
  }

  /**
   * Get the value of the item in the offset position relatively to the selected item, if possible
   *
   * @param {Number} offsetAmount - The offsetAmount to get
   *
   * @returns {(string|undefined)} - The value of the filter if the offset is valid, otherwise undefined
   */
  getValueByOffset (offsetAmount) {
    if (!this.checkOffsetAvailable(offsetAmount)) return undefined
    const selectedItemIndex = this.models.indexOf(this.getSelectedModels()[0])
    const newIndex = selectedItemIndex + offsetAmount
    return this.models[newIndex].getAttribute('value')
  }
}
