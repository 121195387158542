import FilterModel from './filter-model'
import Collection from '../../core/collection'

/**
 * A collection of filters
 */
export default class FiltersCollection extends Collection {
  /**
   * Creates a new FiltersCollection
   *
   * @constructor
   * @param {Object[]|Model[]} [items] - The items to populate collection
   * @param {CollectionOptions} [options] - The collection options
   */
  constructor (items = [], options = {}) {
    super(items, { ...options, model: FilterModel })
  }

  /**
   * Returns the selected filters and it's selectedValues, considering:
   * - Filters with empty values will be skipped
   * - All filter values will be returned as array (both multiSelectable and not)
   *
   * Given: {
   *   filterA: ['2012-10-03'],  // isMultiselectable: false
   *   filterB: [8, 3, 5],       // isMultiselectable: true
   *   filterC: empty/noValue
   * }
   *
   * Returns: {
   *   filterA: ['2012-10-03'],
   *   filterB: [8, 3, 5]
   * }
   *
   * @returns {Object}
   * {type, selectedValues[]}
   */
  getFilterSelectedValues () {
    return this.models
      .map(model => ({
        type: model.getAttribute('type'),
        values: model.getFilterSelectedValues()
      }))
      .filter(filter => filter.values.length)
      .reduce((obj, item) => {
        obj[item.type] = item.values
        return obj
      }, {})
  }

  /**
   * Returns the selected filters and it's selectedValues, considering:
   * - Filters with no values will be returned as well with 'undefined' value
   * - Only multiSelectable filters will be returned as 'Array'
   *
   * Given: {
   *   filterA: ['2012-10-03'],  // isMultiselectable: false
   *   filterB: [8, 3, 5],       // isMultiselectable: true
   *   filterC: empty/noValue
   * }
   *
   * Returns: {
   *   filterA: '2012-10-03',
   *   filterB: [8, 3, 5],
   *   filterC: undefined,
   * }
   *
   * @returns {Object}
   */
  getFiltersSelectedValuesObject () {
    return this.models.reduce((obj, model) => {
      const param = model.getAttribute('type')
      const value = model.getSelectedValues()
      obj[param] = value && value.length
        ? model.isMultiselectable()
          ? value
          : value[0]
        : undefined
      return obj
    }, {})
  }

  /**
   * Clears all selected values
   *
   * @param {ModelActionOptions} [options]
   *
   * @returns {FiltersCollection} - The self instance
   */
  clearSelection (options) {
    this.models.forEach(model => {
      model.clearSelection(options)
    })
    return this
  }
}
