import DateModel from './date-model'
import ValuesCollection from '../values-collection'
import CalendarDataModel from '../../../../modules/components/calendar/calendar-data-model'
import { dateToString, getCenterDate, getConsecutivePeriod, getEdgeDates, getMin, dateStringToDate } from '../../../helpers/dates'

/**
 * A collection of values
 */
export default class DatesCollection extends ValuesCollection {
  /**
   * Creates a new DatesCollection
   *
   * @constructor
   * @param {(Object[]|Model[])} [items] - The items to populate collection
   * @param {CollectionOptions} [options] - The collection options
   */
  constructor (items = [], options = {}) {
    super(items, { ...options, model: DateModel })
    this.fillMissingDates()
    this.calendarDataModel = new CalendarDataModel(this.toCalendarDataModelObject())
    this.calendarDataModel.events.on('change', this.updateFromCalendarDataModel, this)
    this.events.on('updated', this.updateToCalendarDataModel, this)
  }

  /**
   * OVERRIDE Set selected values
   * - ClearSelection before. Because could lead into date inconsistencies
   *
   * @param {Model[]|String[]} values - The values to set for
   * @param {Boolean} [selectedState=true] - The selectedState to set on every value
   * @param {ModelActionOptions} [options]
   *
   * @returns {ValuesCollection} - The self instance
   */
  setSelectedValues (values, selectedState = true, options = {}) {
    this.clearSelection({ silent: true })
    return super.setSelectedValues(values, selectedState, options)
  }

  /**
   * Returns the selected values for filters, manipulated if necessary
   * - In case of dates, we only need the selected edges
   *
   * @returns {Object[]} Plain selected values
   * {value, caption}
   *
   */
  getFilterSelectedValues () {
    const selectedValues = this.getSelectedValues()
    return selectedValues.length <= 2
      ? selectedValues
      : getEdgeDates(selectedValues).map(d => dateToString(d))
  }

  /**
   * Returns the first available date
   *
   * @returns {DateString}
   *
   */
  getFirstAvailableDate () {
    return dateToString(getMin(this.getAvailableValues()))
  }

  getFirstAvailableDateForMonth (month, year) {
    const filteredDates = this.getAvailableDatesForMonth(month, year)
    return filteredDates.length ? dateToString(getMin(filteredDates)) : null
  }

  getAvailableDatesForMonth (month, year) {
    const dates = this.getAvailableValues()
    const convertedDates = dates.map(date => dateStringToDate(date))
    const availableDates = convertedDates.filter(date => (date.getMonth() === month && date.getFullYear() === year))
    return availableDates
  }

  fillMissingDates () {
    const currentValues = this.pick('value').map(obj => obj.value)
    const edgeValues = this.getEdgeValues()
    const desiredValues = getConsecutivePeriod(edgeValues[0], edgeValues[1]).map(d => dateToString(d))
    const missingValues = desiredValues.filter(d => !currentValues.includes(d))
    if (missingValues.length) this.addItems(missingValues.map(v => ({ value: v })), { silent: true })
    return this
  }

  getEdgeValues () {
    return getEdgeDates(this.pick('value').map(obj => obj.value))
  }

  toCalendarDataModelObject () {
    const edgeValues = this.getEdgeValues()
    const selectedValues = this.getSelectedValues()
    return {
      selectedDates: selectedValues,
      selectedDate: selectedValues.length
        ? selectedValues.length === 1
          ? selectedValues[0]
          : (() => {
              const currentDate = this.calendarDataModel
                ? this.calendarDataModel.getAttribute('selectedDate')
                : undefined
              if (!currentDate || (currentDate < selectedValues[0] || currentDate > selectedValues[selectedValues.length])) { return dateToString(getCenterDate(...getEdgeDates(selectedValues))) } else { return currentDate }
            })()
        : '',
      disabledDates: this.where('isAvailable', false).map(model => model.getAttribute('value')),
      startDate: dateToString(edgeValues[0]),
      endDate: dateToString(edgeValues[1])
    }
  }

  updateFromCalendarDataModel () {
    // Skip if selected values are the same, via array comparison
    const modelValues = this.getSelectedValues().sort()
    const calendarValues = this.calendarDataModel.getAttribute('selectedDates').sort()
    if (modelValues.length === calendarValues.length && modelValues.every((v, i) => v === calendarValues[i])) { return this }

    this.clearSelection({ silent: true })
    this.setSelectedValues(this.calendarDataModel.getAttribute('selectedDates'), true)
    return this
  }

  updateToCalendarDataModel (options) {
    this.calendarDataModel.setAttributes(this.toCalendarDataModelObject(), options)
    return this
  }
}
