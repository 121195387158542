import AirportModel from './airport-model'
import ValueModel from '../value-model'
import ValuesCollection from '../values-collection'
import { capitalizeString } from '../../../../js/helpers/string'

/**
 * A collection of values
 */
export default class AirportsCollection extends ValuesCollection {
  /**
   * Creates a new AirportsCollection
   *
   * @constructor
   * @param {(Object[]|Model[])} [items] - The items to populate collection
   * @param {CollectionOptions} [options] - The collection options
   */
  constructor (items = [], options = {}) {
    super(items, { ...options, model: AirportModel })
    this.airportGroups = this.initAirportGroups()
  }

  /**
   * Returns all airport groups found on models
   *
   * @returns {String[]}
   */
  getAirportGroupsData () {
    const airportGroups = []
    this.models.forEach(model => {
      const modelGroup = model.getAttribute('group')
      if (modelGroup && !airportGroups.includes(modelGroup)) airportGroups.push(modelGroup)
    })
    return airportGroups
  }

  /**
   * Returns all models matching given airport group
   *
   * @param {(String|ValueModel)} group - Current group to search for. If a string is given as group, will be considered as value.
   *
   * @returns {AirportModel[]|Model[]}
   */
  getAirportsByGroup (group) {
    const groupName = group instanceof ValueModel ? group.getAttribute('value') : group
    return this.where('group', groupName)
  }

  /**
   * Initialize the airportsGroups collection
   * - Get groups data from models
   * - Creates the collection
   * - Bind changes to group models
   *
   * @returns {ValuesCollection}
   */
  initAirportGroups () {
    const airportGroupsData = this.getAirportGroupsData()

    const airportGroups = new ValuesCollection(airportGroupsData.map(group => ({
      isSelected: false,
      isAvailable: true,
      value: group,
      caption: capitalizeString(group)
    })))

    airportGroups.models.forEach(group => {
      group.events.on('change', changed => {
        if (Object.prototype.hasOwnProperty.call(changed, 'isSelected')) {
          this.getAirportsByGroup(group.value)
            .forEach(airport => airport.setSelection(changed.isSelected, { silent: true }))
          this.events.emit('updated')
        }
      })
    })

    return airportGroups
  }
}
