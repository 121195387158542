import Model from '../../core/model'

/**
 * A value model
 */
export default class ValueModel extends Model {
  /**
   * Creates a new ValueModel
   *
   * @constructor
   * @param {ValueModelAttributes} [attributes] - The model attributes
   * @param {ModelOptions} [options] - Model options
   */
  constructor (attributes = {}, options = {}) {
    super(attributes, options)
  }

  /**
   * Sets a state into the isSelected attribute
   *
   * @param {Boolean} state - True if selected
   * @param {ModelActionOptions} [options] - ModelActionOptions object
   *
   * @returns {Model} - The self instance
   */
  setSelection (state, options) {
    return this.setAttribute('isSelected', state, options)
  }

  /**
   * Try to gets some value from MetaData
   * The expected format is a space separated key:value strings ...
   * EG. "metadata": "Type:Region || ParentId:12"
   *
   * @param {String} valueKey - Key to check
   *
   * @returns {(String|undefined)} - The value as string or undefined if is not found
   */
  getValueFromMetaData (valueKey) {
    try {
      return this.getAttribute('metadata')
        .split('||')
        .find(meta => meta.includes(valueKey))
        .split(':')[1]
        .trim()
        .toLowerCase()
    } catch (err) {
      return undefined
    }
  }
}
